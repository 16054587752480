<template>
    <header class="header">
        <router-link to="/" class="header__logo">
            <img src="@/assets/logo.png" alt=""/>
        </router-link>
        <div class="header__profile">
            <button v-if="false">
                <img src="@/assets/icons/notification.svg" alt="">
            </button>
            <div class="header__menu" >
                <div class="header__name">
                        <span v-if="student.id">ID: {{ student.id }}</span>
                        <span>{{ student.lname || 'Foydalanuvchi' }} {{ student.name || '' }}</span>
                    </div>
                <img src="@/assets/logo.svg" alt="" class="header__avatar no">
                <div class="dropdown" v-if="false">
                    <div class="dropbox">
                        <router-link to="/profile">
                            Shaxsiy sahifa
                            <el-icon><operation/></el-icon>
                        </router-link>
                        <button @click="logout">
                            Chiqish
                            <el-icon><switch-button/></el-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data: () =>  ({
        search:'',
        
    }),
    computed:{
        ...mapGetters([
            'url',
            'student'
        ])
    },
    methods: {
        ...mapActions([
            'exit'
        ]),
        dark(){
            this.$store.commit('dark',!this.$store.getters.dark)
        },
        logout(){
            if (confirm('Tizimdan chiqmoqchimisiz?')){
                this.exit()
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/styles/part/header.scss';
</style>