<template>
  <component :is="layout"/>
</template>


<script>
import StudentLayout from '@/layout/userLayout.vue';
import AuthLayout from '@/layout/authLayout.vue';
export default {
  components: {
    'user-layout': StudentLayout,
    'auth-layout': AuthLayout,
  },
  computed:{
    layout(){
      return this.$store.getters.layout
    }
  },
  methods:{
    checkUser(){
      if (this.$cookies.isKey('angren-user')){
        this.$store.dispatch('checkUser',this.$cookies.get('angren-user'))
      } else {
        this.$router.push('/login')
      }
      
    }
  },
  mounted(){
    this.checkUser()
    document.title = 'UMFT'
  }
}
</script>

<style lang="scss">
@import './styles/reset.scss';
@import './styles/grid.scss';
@import './styles/main.scss';
@import './styles/lib/class.scss';
</style>