<template>
    <el-row :gutter="30" :class="`${student.status == 0 && !student.testproccess && student.online !==1 ? 'reverse':''}`">
        <el-col :span="student.status == -1 ? 24 : 16" :xs="24">
            <div class="box" v-if="student.status == 0 && (student.online == 1 || student.examActive && student.testproccess)">
                <div class="card" v-if="testproccess" >
                    <img :src="`${url}/${testproccess?.photo}`" alt="" v-if="testproccess?.photo !=='no-photo'">
                    <div class="img" v-else>
                        <img src="@/assets/logo.png" alt="">
                    </div>
                    <div class="card__body">
                        <div class="title">{{ testproccess?.title }}</div>
                        <div class="text" v-html="testproccess.text"/>
                        <div class="card__row">
                            Ajratilgan vaqt:
                            <span class="el-tag">{{ testproccess?.time }}</span>
                        </div>
                        <div class="card__row">
                            Umumiy testlar soni:
                            <span class="el-tag">{{ testproccess?.testsCount }} ta</span>
                        </div>
                        <div class="card__row">
                            Urinishlar soni
                            <span class="el-tag">
                                <span>{{ testproccess?.trycount }} ta / </span> 
                                <span>{{ testproccess?.testpack?.trycount }} ta</span>
                            </span>
                        </div>
                        <div class="card__row">
                            Natija: 
                            <span :class="`el-tag testproccess__status ${testproccess.result ? 'success' : 'warning'}`">{{ testproccess.result ? 
                                testproccess.result.balls >= 0 ? 
                                testproccess.result.balls + ' ball (' + (testproccess.result.balls/testproccess.result.allballs*100).toFixed(2)+' %)' 
                                : 'Jarayonda'
                                : 'Ishtirok etilmagan!' 
                            }}</span>
                        </div>
                        <el-popconfirm 
                            v-if="testproccess?.trycount <= testproccess?.testpack?.trycount && testproccess.result.endtrycount < testproccess.testpack?.trycount"
                            title="Testni boshlashga tayyormisiz?"
                            @confirm="start(testproccess._id,testproccess.trycount)"
                            >
                            <template #reference>
                                <el-button type="success">Topshirish</el-button>
                            </template>
                        </el-popconfirm>
                        
                    
                    </div>
                </div>
            </div>
            <div class="box mb-20" v-else>
                <div v-if="student.status == -1" class="welcome__not">
                    <img src="@/assets/status_0.png" alt="">
                    <h2 class="el-tag el-tag--warning" >Hozircha ariza to'ldirmagansiz</h2>
                    <el-button type="success"  @click="$router.push('/requests')">Ariza jo'natish</el-button>
                </div>
                <div v-if="student.status == 1" class="welcome__not">
                    <img src="@/assets/status_1.png" alt="">
                    <h2 class="el-tag el-tag--warning pointer" @click="$router.push('/requests')">Arizangiz ko'rib chiqilmoqda</h2>
                </div>
                <div v-if="student.status == 0 && [0,4].includes(student.result) && !student.early" class="welcome__not">
                    <img src="@/assets/status_1.png" alt="">
                    <h2 class="el-tag el-tag--success">Arizangiz qabul qilindi</h2>
                    <h3 class="mb-20" v-if="student.online == 1">
                        Talabalikka qabul imtihonidan o'ting
                    </h3>
                    <el-button v-if="student.online == 1" type="primary" @click="$router.push('/exam')">Imtihonga kirish</el-button>
                </div>
                <div v-if="student.status == 0 && (student.result == 1 || student.early)" class="welcome__not">
                    <img src="@/assets/status_1.png" alt="">
                    <h2 class="el-tag el-tag--success">Talabalikka tavsiya etildingiz</h2>
                    <el-button v-if="student.online == 1" type="primary" @click="$router.push('/exam')">Imtihonga kirish</el-button>
                </div>
                <div v-if="student.status == 0 && student.result == 2 && !student.early" class="welcome__not">
                    <img src="@/assets/status_1.png" alt="">
                    <h2 class="el-tag el-tag--error">Talabalikka tavsiya etilmadingiz</h2>
                </div>
                <div v-if="student.status == 2" class="welcome__not">
                    <img src="@/assets/status_1.png" alt="">
                    <h2 class="el-tag el-tag--danger">Arizangiz bekor qilindi</h2>
                    <h4>Sabab: {{ student.comment }}</h4>
                    <el-button type="success" @click="$router.push('/requests')">Arizani tahrirlash</el-button>
                </div>
            </div>
            

        </el-col>
        <el-col :span="8" v-if="student.status !== -1" :xs="24">
            <div class="box mb-20">
                <div class="profile">
                    <img :src="`${url}/${student.img?.at(0)?.response}`" v-if="student.img.length > 0" alt="" class="profile__avatar">
                    <img :src="`${url}/${student.pasImg?.at(0)?.response}`" v-else-if="student.pasImg.length > 0" alt="" class="profile__avatar">
                    <img src="@/assets/logo.svg" v-else alt="" class="header__avatar no">
                    <div class="name" v-if="student.lname || student.name || student.secondname">{{ student.lname }} {{ student.name }} {{ student.secondname }}</div>
                    <div class="name" v-else>
                        Abiturient
                    </div>
                    <div class="id">ID: {{ student.id }}</div>
                    <div class="subtitle" v-if="student.direction">Yo'nalish: <span>{{ student.direction?.title }}</span></div>
                    <div class="subtitle" v-if="student.studyPart >= 0">Ta'lim turi: <span>{{ studyPart[student.studyPart] }}</span></div>
                    <div class="subtitle" v-if="student.studyType >= 0">Ta'lim shakli: <span>{{ studyTypes[student.studyType] }}</span></div>
                    <div class="subtitle" v-if="student.language >= 0">Ta'lim tili: <span>{{ languages[student.language]?.title }}</span></div>
                    
                </div>
            </div>
            <div class="box mb-20" v-if="student.status == 0 && student.online == 1">
                <div  class="welcome__not">
                    <img src="@/assets/status_1.png" alt="" class="box__side">
                    <h2 class="el-tag el-tag--success" v-if="!student.result">Arizangiz qabul qilindi</h2>
                    <h2 class="el-tag el-tag--success" v-if="student.result == 1">Talabalikka tavsiya etildingiz</h2>
                    <h2 class="el-tag el-tag--danger" v-if="student.result == 2">Talabalikka tavsiya etilmadingiz!</h2>
                </div>
            </div>
            <div class="box mb-20" v-if="
            student.status == 0 && student.link?.length > 0 && [0,4].includes(student.result)
            || student.link?.length > 0 && student.early && student.status == 0">
                <div  class="welcome__not">
                    <img src="@/assets/status_2.png" alt="" class="box__side">
                    <h4 class="text-center">
                        Qabul natijalariga ko'ra o'qish uchun sizga shartnoma rasmiylashtirildi. Shartnomani shu yerda ko'rishingiz yoki yuklab olishingiz mumkin. Shartnomaning to'lov muddatiga ahamiyat bering (3.2-band)
                    </h4>
                    <h2 class="el-tag el-tag--success">Shartnomani yuklab oling</h2>
                    <div class="df align-items-center justify-content-between">
                        <el-button type="primary" @click="$router.push('/report')">Ko'rish</el-button>
                        <el-button type="success" @click="download()">Yuklash</el-button>
                    </div>
                </div>
            </div>
        </el-col>
    </el-row>
</template> 

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
export default {
    data: () => ({
        toggle:false,
        process:{},
        languages:[
            {title: "O'zbekcha", value: 0},
            {title: "Ruscha", value: 1},
        ],
    }),
    computed:{
        ...mapGetters([
            'student',
            'url',
            'studyTypes',
            'foreign',
            'studyPart',
            'testproccess'
        ])
    },
    methods:{
        ...mapActions([
            'getTestproccess',
            'startPushResult',
            'getReport'
        ]),
        start(id,trycount){
            this.startPushResult({ procid:id,check:1,trycount})
        },
        async getData(){
            let res = await this.getReport()
            if (res.status === 200) {
                if (res.data !== 'no'){
                    this.student.link = `${this.url}/files/reports/${(res.data._id)}.pdf`
                } 
            }
        },
        download(){ 
            axios({ 
                url: this.student.link, 
                method: 'GET', 
                responseType: 'blob', 
            }).then((response) => { 
                var fileURL = window.URL.createObjectURL(new Blob([response.data])); 
                var fileLink = document.createElement('a'); 
                fileLink.href = fileURL; 
                fileLink.setAttribute('download', `shartnoma.pdf`); 
                document.body.appendChild(fileLink); 
                fileLink.click(); 
            }) 
        },

    },
    mounted(){
        this.getTestproccess()
        this.getData()
    }
}
</script>

<style lang="scss">
@import '@/styles/view/welcome.scss';
</style>