import cookie from 'vue-cookies'
import router from "@/router"
import { ElMessage } from 'element-plus'

export const auth = {
    state: () => ({
        user: {
            name:'',
            phone:''
        },
        username:'',
        token:'',
        header:{},
        role: '',
    }),
    getters: {
        settingsLinks(state){
            return state.settingsLinks.filter(setting => setting.meta.role.includes(state.role))
        },
        user(state){
            return state.user
        },
        token(state){
            return state.token
        },
        header(state){
            return state.header
        },
        role(state){
            return state.role
        },
        username(state){
            return state.username
        }
    },
    mutations:{  
        role(state,payload){
            state.role = payload
        },
        user(state,payload){
            state.user = payload
        },
        setToken(state,payload){
            state.token = payload
        },
        header(state,payload){
            state.header = payload
        },
        username(state,payload){
            state.username = payload
        }
    },
    actions:{
        studentAuth(context,payload){
            context.dispatch('postAxios',{url:'cabinet/login',data:payload})
            .then(res=> {
                if (res.status == 200){
                    let {token,student} = res.data
                    context.commit('student',student)
                    // console.log(student);
                    context.commit('setToken',token)
                    context.commit('role',student.role)
                    context.commit('header',{
                        "authorization" : `Bearer ${token}`
                    })
                    cookie.set('angren-user',{
                        user: student._id,
                        token:token,
                    })
                    if (student.status == -1) {
                        router.push('/requests')
                    } else {
                        router.push('/')
                    }
                    context.dispatch('setLayout',`user-layout`)
                } 
            }).catch(err => {
                let {response} = err
                cookie.remove('angren-user')
                ElMessage({
                    message: response.data?.message,
                    type: 'warning',
                })
                // console.clear()
            })
        },
        checkActive({dispatch},payload){
            return dispatch('getAxios',{url:'auth/login',search:payload})
        },
        checkUser(context,payload){
            let {token} = payload
            context.commit('setToken',token)
            context.commit('header',{
                "authorization" : `Bearer ${token}`
            })
            context.dispatch('getAxios',{url:'cabinet/check'})
            .then(res => {
                if (res.status == 200){
                    context.commit('student',res.data)  
                    context.commit('role','user')
                    context.commit('layout',`user-layout`)                    
                } else {
                    cookie.remove('angren-user')
                    context.commit('layout','auth-layout')
                }
            }).catch(e => {
                console.log(e)
                cookie.remove('angren-user')
                context.commit('layout','auth-layout')
                ElMessage({
                    message: 'Serverda xatolik',
                    type: 'error',
                })
            })
        },
        exit(context){
            cookie.remove('angren-user')
            context.commit('layout','auth-layout')
            context.commit('user',{})
            context.commit('setToken','')
            context.commit('role','')
            ElMessage({
                message: 'Tizimdan chiqdingiz',
                type: 'warning',
            })
            router.push('/login')
        },
        checkLogin(context,payload){
            return context.dispatch('postAxios',{url:`auth/checkphone`,data:payload},{headers:context.getters.header})
        },
        checkPhone({dispatch,getters},payload){
            return dispatch('postAxios',{url:`auth/checkphone`,data:payload},{headers:getters.header})
        },
        authSms({dispatch},payload){
            return dispatch('postAxios',{
                url: `cabinet/sendsms`,
                data: payload
            })
        },
        checkEmail(context,payload){
            return context.dispatch('postAxios',{url:`auth/checkemail`,data:payload},{headers:context.getters.header})
        },
        getProfile(context){
            return context.dispatch('getAxios',{url:'auth/my'})
        },
        saveProfile(context,payload){
            context.dispatch(`putAxios`,{url:'auth/save',data:payload})
            .then(res => {
                if (res.status == 200){
                    context.commit('user',res.data)
                    ElMessage({
                        message: 'Ma`lumotlar yangilandi',
                        type: 'success',
                    })
                    router.push('/');
                }
            })
        },
    }
}